import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {CodeSandbox} from "@/feature-docs/DocsComponents.jsx";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h2: "h2",
    h4: "h4",
    hr: "hr",
    img: "img",
    li: "li",
    p: "p",
    pre: "pre",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(_components.p, {
      children: ["Since the release of Chrome 106 (Sept 2022) we've been blessed with the long-awaited ability to query a DOM node for its size in CSS, and style accordingly\n", _jsx(_components.a, {
        href: "https://caniuse.com/css-container-queries",
        children: "https://caniuse.com/css-container-queries"
      })]
    }), "\n", _jsx(_components.p, {
      children: "While stylistically similar to the media queries you're familiar with, fundamentally they function differently and require a slightly different mental model and approach. No longer do we care about the size of the window, what matters is the size of the component itself. This unlocks powerful new cababilities. It allows components to be completely portable."
    }), "\n", _jsx(_components.p, {
      children: "ex: Lets say one day we decided to re-design the League postmatch and move scoreboard to the skinny sidebar. That would be all of 5s of work copy and pasting where it's located. The scoreboard has zero care of the window size, just how big it is (as is contrained by its parent.)"
    }), "\n", _jsx(_components.hr, {}), "\n", _jsx(_components.h4, {
      id: "use-bespoke-sizes-every-component-is-going-to-have-different-places-where-its-layout-breaks-down-no-more-use-of-things-like-mobile-because-that-no-longer-make-sense-there-are-no-global-breakpoints-find-out-where-the-component-falls-apart-and-adjust-accordingly",
      children: _jsx(_components.a, {
        href: "#use-bespoke-sizes-every-component-is-going-to-have-different-places-where-its-layout-breaks-down-no-more-use-of-things-like-mobile-because-that-no-longer-make-sense-there-are-no-global-breakpoints-find-out-where-the-component-falls-apart-and-adjust-accordingly",
        children: "Use bespoke sizes. Every component is going to have different places where it's layout breaks down. No more use of things like \"mobile\" because that no longer make sense. There are no global \"breakpoints.\" Find out where the component falls apart and adjust accordingly"
      })
    }), "\n", _jsx(_components.hr, {}), "\n", _jsx(_components.p, {
      children: "Chrome dev tools provides a nice visual representation of containers in the DOM (exactly like grids/flex containers)."
    }), "\n", _jsxs(_components.p, {
      children: ["For a practical example in app see the League ", _jsx(_components.a, {
        href: "https://github.com/theblitzapp/blitz-app/blob/master/src/game-lol/MatchScoreboard.jsx",
        children: "postmatch scoreboard"
      }), " (file named MatchScoreboard.jsx at time of writing) and ", _jsx(_components.a, {
        href: "https://github.com/theblitzapp/blitz-app/blob/master/src/game-lol/MatchScoreboard.style.jsx",
        children: "corresponding css file"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "best-practices",
      children: _jsx(_components.a, {
        href: "#best-practices",
        children: "Best practices"
      })
    }), "\n", _jsx(_components.p, {
      children: "There are 2 things that I would consider \"best practices\" and should basically always be done for any container query."
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Name the container. Naming is not required and when a name isnt provided to a query the css parser will just go up the tree and find the nearest one. This isn't always desired especially when you have multiple nested container."
      }), "\n", _jsx(_components.li, {
        children: "It's usually a good idea to add an \"extra\" DOM node whose sole purpose is to be defined as the container (see example below.) The reason for this is because when you query a container to make changes, you can't make changes to the container itself, only children of that container."
      }), "\n"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-css",
        children: ".container {\n  container-type: inline-size; /* basically means \"watch width\" */\n  container-name: card; /* arbitrary name you can later query/reference */\n\n  /* I would advise against applying any other styles here */\n}\n.contents {\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n\n  /* scss nesting */\n  @container card (width <= 500px) {\n    flex-direction: column;\n  }\n}\n"
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-html",
        children: "<div class=\"container\">\n  <div class=\"contents\">...</div>\n</div>\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "semi-practical-example",
      children: _jsx(_components.a, {
        href: "#semi-practical-example",
        children: "Semi-practical example"
      })
    }), "\n", _jsx(CodeSandbox, {
      id: "inspiring-babbage-nkv8tc"
    }), "\n", _jsx(_components.hr, {}), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.img, {
        src: "https://i.imgflip.com/2ynjel.jpg",
        alt: "Alt text"
      }), "\nThe future now that we no longer need media queries"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
